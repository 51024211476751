import { get, isEmpty, last } from 'lodash';

import {
  ALL_MODELS_QUERY,
  ARTICLE_LANDING_PAGE_QUERY,
  ARTICLE_PAGE_QUERY,
  CONFIGURATION_QUERY,
  HOME_PAGE_QUERY,
  JOURNEY_LANDING_PAGE_QUERY,
  JOURNEY_PAGE_QUERY,
  JOURNEY_TAG_PAGE_QUERY,
  PAGE_BY_ID_QUERY,
  PAGE_LINK_BY_ID_QUERY,
  REWARD_PAGE_QUERY,
  ROOT_PAGES_QUERY,
  TAG_QUERY,
} from '@/lib/datocms/queries';
import {
  Article,
  ArticleLink,
  Configuration,
  CategoryQueryBySlugParams,
  GetCategoryBySlugParams,
  GetPageBySlugParams,
  HomePage,
  HomePageLink,
  Journey,
  JourneyLandingPage,
  JourneyLandingPageLink,
  JourneyLink,
  ModelLink,
  Page,
  PageLink,
  PagePaths,
  PaginationMeta,
  Reward,
  RewardLink,
  Tag,
  TagLink,
  TagPageType,
  TagQueryBySlugParams,
} from '@/lib/types';
import { captureMessage } from '@/utils/sentry';
import { request } from '@/lib/datocms';
import { getPathsBySlug } from './utils/get-paths-by-slug';
import { getPaginationMeta } from './utils/get-pagination-meta';
import { isSlugValid } from './utils/is-slug-valid';

const DATOCMS_MAX_PAGE_SIZE = 100;

export const getAllPathsBySlug = async ({ slug }: GetPageBySlugParams): Promise<PagePaths> => {
  if (isEmpty(slug)) {
    return [];
  }
  const slugArray = [slug].flat();

  const { allPages } = await request({
    query: ROOT_PAGES_QUERY,
    variables: { rootSlug: slugArray[0] },
  });

  const paths = getPathsBySlug(allPages, slugArray as string[]);
  return paths;
};

const getPageIdAndPathsBySlug = async ({
  slug,
}: GetPageBySlugParams): Promise<{ pageId: string | undefined | null; paths: PagePaths } | null> => {
  if (isEmpty(slug)) {
    return null;
  }
  const paths = await getAllPathsBySlug({ slug });
  const pageId = get(last(paths), 'id');
  return { pageId, paths };
};

export const getPageBySlug = async ({
  slug,
}: GetPageBySlugParams): Promise<{ page?: Page; paths: PagePaths } | null> => {
  if (!isSlugValid(slug)) {
    return null;
  }

  const { pageId, paths } = (await getPageIdAndPathsBySlug({ slug })) || {};

  if (!pageId || !paths) {
    return null;
  }

  const { page } = await request({
    query: PAGE_BY_ID_QUERY,
    variables: { id: pageId },
  });

  return { page, paths };
};

export const getPageLinkBySlug = async ({
  slug,
}: GetPageBySlugParams): Promise<{ page?: PageLink; paths: PagePaths } | null> => {
  const { pageId, paths } = (await getPageIdAndPathsBySlug({ slug })) || {};
  if (!pageId || !paths) {
    return null;
  }
  const { page } = await request({
    query: PAGE_LINK_BY_ID_QUERY,
    variables: { id: pageId },
  });
  return { page, paths };
};

export const getArticleLandingPageBySlug = async ({
  page = 1,
  perPage = 12,
  categorySlug,
}: CategoryQueryBySlugParams): Promise<{ articles: Article[]; page: Page; meta: PaginationMeta } | null> => {
  const categoryPageData = await getPageBySlug({ slug: categorySlug });
  const categoryPageId = categoryPageData?.page?.id;

  if (isEmpty(categoryPageId)) {
    captureMessage('Unable to find category page for article landing', {
      extra: {
        categorySlug,
        page,
        perPage,
      },
    });

    return null;
  }

  const graphqlRequest = {
    query: ARTICLE_LANDING_PAGE_QUERY,
    variables: { categoryPageId, skip: (page - 1) * perPage, first: perPage },
  };

  const data = await request<{ allArticlesMeta: { count: number }; articles: Article[]; allPages: Page[] }>(
    graphqlRequest
  );

  const articleLandingPage = {
    // TODO: this is to get around an error in the DatoCMS API where filtering by parent id does not always return
    // a result. The query now fetches all pages with the required slug, and we filter for the exact page here.
    page: data.allPages?.find((p) => p.parent?.id === categoryPageId) as Page,
    articles: data.articles,
    meta: getPaginationMeta(page, perPage, data.allArticlesMeta.count),
  };

  return articleLandingPage;
};

export const getArticlePageBySlug = async ({
  categorySlug,
  slug,
}: CategoryQueryBySlugParams): Promise<{ article?: Article } | null> => {
  if (!isSlugValid(slug)) {
    return null;
  }

  const categoryPageData = await getPageBySlug({ slug: categorySlug });
  const categoryPageId = categoryPageData?.page?.id;
  if (isEmpty(categoryPageId)) {
    captureMessage('Unable to find category page for article', {
      extra: {
        categorySlug,
        slug,
      },
    });

    return null;
  }
  const graphqlRequest = {
    query: ARTICLE_PAGE_QUERY,
    variables: { slug, categoryPageId },
  };
  return request(graphqlRequest);
};

export const getRewardPageBySlug = async ({
  categorySlug,
  slug,
}: CategoryQueryBySlugParams): Promise<{ reward?: Reward } | null> => {
  if (!isSlugValid(slug)) {
    return null;
  }

  const categoryPageData = await getPageBySlug({ slug: categorySlug });
  const categoryPageId = categoryPageData?.page?.id;

  if (isEmpty(categoryPageId)) {
    captureMessage('Unable to find category page for reward', {
      extra: {
        categorySlug,
        slug,
      },
    });

    return null;
  }

  const graphqlRequest = {
    query: REWARD_PAGE_QUERY,
    variables: { slug, categoryPageId },
  };

  return request(graphqlRequest);
};

const getTagBySlug = async ({ slug }: GetCategoryBySlugParams): Promise<{ tag: Tag }> => {
  const graphqlRequest = {
    query: TAG_QUERY,
    variables: { slug },
  };
  return request(graphqlRequest);
};

export const getJourneyTagPageBySlug = async ({
  tagSlug,
  page = 1,
  perPage = 11,
}: TagQueryBySlugParams): Promise<{ tag: TagPageType; journeys: Journey[]; meta: PaginationMeta } | null> => {
  const tagData = await getTagBySlug({ slug: tagSlug });
  const tagId = tagData?.tag?.id;
  if (isEmpty(tagId)) {
    captureMessage('Unable to find tag page for journey tag page', {
      extra: {
        tagId,
        page,
        perPage,
      },
    });

    return null;
  }
  const graphqlRequest = {
    query: JOURNEY_TAG_PAGE_QUERY,
    variables: { tagId, skip: (page - 1) * perPage, first: perPage },
  };

  const data = await request<{ tag: TagPageType; allJourneysMeta: { count: number }; journeys: Journey[] }>(
    graphqlRequest
  );

  const journeyTagPage = {
    tag: data.tag,
    journeys: data.journeys,
    meta: getPaginationMeta(page, perPage, data.allJourneysMeta.count),
  };

  return journeyTagPage;
};

export const getJourneyPageBySlug = async ({
  tagSlug,
  slug,
}: TagQueryBySlugParams): Promise<{ journey?: Journey } | null> => {
  if (!isSlugValid(slug)) {
    return null;
  }

  const tagData = await getTagBySlug({ slug: tagSlug });
  const tagId = tagData?.tag?.id;
  if (isEmpty(tagId)) {
    captureMessage('Unable to find tag page for journey', {
      extra: {
        tagId,
        slug,
      },
    });

    return null;
  }
  const graphqlRequest = {
    query: JOURNEY_PAGE_QUERY,
    variables: { slug, tagId },
  };
  return request(graphqlRequest);
};

export const getJourneyLandingPage = async (): Promise<{ journeyLandingPage?: JourneyLandingPage }> => {
  const graphqlRequest = {
    query: JOURNEY_LANDING_PAGE_QUERY,
    variables: {},
  };
  return request(graphqlRequest);
};

export const getHomePage = async (): Promise<{ homePage?: HomePage }> => {
  const graphqlRequest = {
    query: HOME_PAGE_QUERY,
    variables: {},
  };
  return request(graphqlRequest);
};

export const getConfiguration = async (): Promise<{ configuration?: Configuration }> => {
  const graphqlRequest = {
    query: CONFIGURATION_QUERY,
    variables: {},
  };
  return request(graphqlRequest);
};

type AllModelsResult = {
  articles: ArticleLink[];
  homePage?: HomePageLink;
  journeyLandingPage?: JourneyLandingPageLink;
  journeys: JourneyLink[];
  pages: PageLink[];
  rewards: RewardLink[];
  tags: TagLink[];
};

const getAllModelsByPage = async (page = 1, perPage = DATOCMS_MAX_PAGE_SIZE): Promise<AllModelsResult> => {
  try {
    const data = await request({
      query: ALL_MODELS_QUERY,
      variables: { skip: (page - 1) * perPage, first: perPage },
    });
    return data;
  } catch (error) {
    console.error('Error fetching all models', error);

    console.error({ page, perPage });

    return {
      articles: [],
      homePage: undefined,
      journeyLandingPage: undefined,
      journeys: [],
      pages: [],
      rewards: [],
      tags: [],
    };
  }
};

export const getAllModels = async (): Promise<AllModelsResult> => {
  let page = 1;
  const perPage = DATOCMS_MAX_PAGE_SIZE;
  const result: AllModelsResult = {
    articles: [],
    homePage: undefined,
    journeyLandingPage: undefined,
    journeys: [],
    pages: [],
    rewards: [],
    tags: [],
  };
  let getMore = true;
  do {
    // Get the current page results
    // eslint-disable-next-line no-await-in-loop
    const { pages, articles, rewards, tags, journeys, homePage, journeyLandingPage } = await getAllModelsByPage(
      page,
      perPage
    );

    // Merge with existing results
    result.articles = result.articles.concat(articles);
    result.pages = result.pages.concat(pages);
    result.rewards = result.rewards.concat(rewards);
    result.tags = result.tags.concat(tags);
    result.journeys = result.journeys.concat(journeys);
    result.homePage = homePage;
    result.journeyLandingPage = journeyLandingPage;

    // Get more only when all array items are returning less than the per page limit
    const isEveryDataArrayEmpty = Object.values({ pages, articles, rewards, tags, journeys }).every(
      (value) => value.length < perPage
    );
    getMore = !isEveryDataArrayEmpty;
    page += 1;
  } while (getMore);

  return result;
};

type SitemapModel = ModelLink & { updatedAt: string };

export const getSitemap = async (): Promise<Array<SitemapModel>> =>
  Object.values(await getAllModels()).flat() as Array<SitemapModel>;
