import queryString, { StringifiableRecord } from 'query-string';

import { addBreadcrumb } from '@/utils/sentry';

type FetcherProps = {
  body?: { query: string; variables: unknown };
  params?: StringifiableRecord;
  token?: string | undefined;
  headers?: Record<string, unknown>;
};

type FetcherReturnType = { errors: Record<string, unknown>; data: never };

type ConfigProps = { method: string; mode: RequestMode | undefined; headers: HeadersInit | undefined; body?: string };

const fetcher = async (
  endpoint: string,
  { body, params = {}, token, ...fetchConfig }: FetcherProps = {}
): Promise<FetcherReturnType> => {
  const urlWithParams = queryString.stringifyUrl({
    url: endpoint,
    query: params,
  });

  const headers: { 'content-type': string; authorization?: string } = { 'content-type': 'application/json' };

  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  const config: ConfigProps = {
    method: body ? 'POST' : 'GET',
    mode: 'cors',
    ...fetchConfig,
    headers: {
      ...headers,
      ...fetchConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  const response = await fetch(urlWithParams, config);
  const json = await response.json();

  if (!response.ok) {
    addBreadcrumb({
      category: 'api',
      message: 'Fetch Error',
      level: 'error',
      data: { url: urlWithParams, config, response: JSON.stringify(json) },
    });
    throw new Error(
      JSON.stringify(
        {
          category: 'api',
          message: 'Fetch Error',
          level: 'error',
          data: { url: urlWithParams, config, response: json },
        },
        null,
        2
      )
    );
  }

  return json;
};

export { fetcher };
