import { faFacebook, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';

import { ColourStrings, PagePaths } from '@/lib/types';

export const HUNDRED_YEAR_TAKEOVER = process.env.NEXT_PUBLIC_HUNDRED_YEAR_TAKEOVER === 'true';

export const CATEGORY_SLUGS = {
  CARS_AND_DRIVING: 'cars-and-driving',
  COMMUNITY: 'community',
  INSURANCE_AND_FINANCE: 'insurance-and-finance',
  MEMBERSHIP: 'membership',
  TRAVEL_AND_EXPERIENCE: 'travel-and-experience',
};

export const Colors = {
  ActiveBlue: 'Active Blue',
  Aqua: 'Aqua',
  Black: 'Black',
  DeepBlue: 'Deep Blue',
  Green: 'Green',
  Lavender: 'Lavender',
  Lime: 'Lime',
  Orange: 'Orange',
  Pink: 'Pink',
  Teal: 'Teal',
  Watermelon: 'Watermelon',
  Jewel: 'Jewel',
  SanMarino: 'SanMarino',
  JapaneseMaple: 'JapaneseMaple',
  RedViolet: 'RedViolet',
  ElectricViolet: 'ElectricViolet',
  Grenadier: 'Grenadier',
  SurfieGreen: 'SurfieGreen',
  Muesli: 'Muesli',
} as const;

export const SLUGS = {
  RELATED_ARTICLES: 'related-articles',
  MEMBER_REWARDS: 'member-rewards',
  BECOME_A_MEMBER: 'become-a-member',
  JOURNEYS: 'journeys',
  PAGE_NOT_FOUND: '404',
  STYLEGUIDE: 'styleguide',
};

export const SOCIAL_PLATFORMS = {
  FACEBOOK: { title: 'Facebook', url: 'https://facebook.com/RACTOfficial/', icon: faFacebook },
  INSTAGRAM: { title: 'Instagram', url: 'https://www.instagram.com/ractofficial', icon: faInstagram },
  YOUTUBE: { title: 'YouTube', url: 'https://www.youtube.com/channel/UCzHh1CXkBoVQLKNp3xi0Nog', icon: faYoutube },
  LINKEDIN: { title: 'LinkedIn', url: 'https://www.linkedin.com/company/ract', icon: faLinkedin },
};

export const REWARD_CATEGORIES_DATA: {
  [key: string]: {
    colour: ColourStrings;
    title: string;
    icon: string;
  };
} = {
  'activities-and-experiences': {
    colour: Colors.Lavender,
    title: 'Activities & Experiences',
    icon: 'map-marker-alt',
  },
  'cars-and-driving': {
    colour: Colors.Teal,
    title: 'Cars & Driving',
    icon: 'car',
  },
  'food-and-entertainment': {
    colour: Colors.Pink,
    title: 'Food & Entertainment',
    icon: 'utensils',
  },
  'health-and-fitness': {
    colour: Colors.Orange,
    title: 'Health & Fitness',
    icon: 'dumbbell',
  },
  'home-and-garden': {
    colour: Colors.Lime,
    title: 'Home & Garden',
    icon: 'shovel',
  },
  'services-and-other': {
    colour: Colors.Green,
    title: 'Services & Other',
    icon: 'handshake-alt',
  },
  'shopping-and-gifts': {
    colour: Colors.Aqua,
    title: 'Shopping & Gifts',
    icon: 'gifts',
  },
  'travel-and-accommodation': {
    colour: Colors.Watermelon,
    title: 'Travel & Accommodation',
    icon: 'globe-stand',
  },
};

export const URLS = {
  RACT_APPLE_APP_STORE: 'https://apps.apple.com/au/app/ract-fuel-saver/id1539616413',
  RACT_GOOGLE_PLAY_STORE: 'https://play.google.com/store/apps/details?id=au.com.ract.fuelsaver',
  RACT_HELP: 'https://help.ract.com.au',
  RACT_HUB: 'https://account.ract.com.au',
  RACT_INSURANCE: 'https://insurance.ract.com.au',
  RACT_JOIN_LIFESTYLE: 'https://secure.ract.com.au/JoinLifestyleForm',
  RACT_JOIN_ROADSIDE: 'https://secure.ract.com.au/JoinMembershipForm?product=Ultimate-Monthly',
  RACT_LOAN_APPLICATION: 'https://www.racv.com.au/finance/ract-loan-application.html',
  RACT_MAKE_A_PAYMENT: 'https://secure.cardaccess.com.au/ecom/casconnect/ract/index_A.py',
  RACT_WEBSITE: 'https://www.ract.com.au',
};

export const RACT_WEBSITE_REGEX = new RegExp('^https://(www.)*ract.com.au', 'i');
export const RACT_SUB_DOMAIN_REGEX = new RegExp('^https?://(.*).ract.com.au', 'i');
export const RACT_EXTERNAL_DOMAINS: string[] = ['https://secure.cardaccess.com.au'];

export const PHONE_NUMBERS = {
  RACT_GENERAL_ENQUIRIES: { LINK: 'tel:132722', PRETTY: '13 27 22' },
  RACT_ROADSIDE_ASSISTANCE: { LINK: 'tel:131111', PRETTY: '13 11 11' },
};

export const PAGE_REVALIDATE_INTERVAL = Number(process.env.PAGE_REVALIDATE_INTERVAL) || 60;

export const PAGE_TITLE_SUFFIX = '– RACT';

export const STATIC_PATH_LIMIT = process.env.VERCEL_ENV !== 'production' ? 5 : Infinity;

// @NOTE: These are hardcoded because it is a single instance page so
// can't be traversed like the other pages can be in order to determine breadcrumb
export const MEMBERSHIP_JOURNEY_PAGE_PATHS: PagePaths = [
  {
    id: 'membership',
    slug: 'membership',
    title: 'Membership',
    path: '/membership',
  },
  {
    id: 'journeys',
    slug: 'journeys',
    title: 'Journeys',
    path: '/membership/journeys',
  },
];

export const EXCLUDED_ROUTES = [`/${SLUGS.PAGE_NOT_FOUND}`, `/${SLUGS.STYLEGUIDE}`];
